'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _UiIcon = require('./UiIcon.vue');

var _UiIcon2 = _interopRequireDefault(_UiIcon);

var _autofocus = require('./directives/autofocus');

var _autofocus2 = _interopRequireDefault(_autofocus);

var _HasTextInput = require('./mixins/HasTextInput');

var _HasTextInput2 = _interopRequireDefault(_HasTextInput);

var _ValidatesInput = require('./mixins/ValidatesInput');

var _ValidatesInput2 = _interopRequireDefault(_ValidatesInput);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-textbox',
  props: {
    placeholder: String,
    type: {
      type: String,
      default: 'text'
    },
    multiLine: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 2
    },
    maxLength: Number,
    trimValue: {
      type: Boolean,
      default: true
    },
    validateOnBlur: {
      type: Boolean,
      default: false
    },
    autocomplete: String,
    autofocus: {
      type: Boolean,
      default: false
    },
    min: Number,
    max: Number,
    step: {
      type: String,
      default: 'any',
      coerce: String
    }
  },
  watch: {
    value: function value() {
      if (this.ignoreValueChange) {
        return;
      }

      if (!this.dirty) {
        this.dirty = true;
      }

      if (!this.validateOnBlur) {
        this.validate();
      }
    }
  },
  data: function data() {
    return {
      ignoreValueChange: false
    };
  },

  computed: {
    showIcon: function showIcon() {
      return Boolean(this.icon);
    },
    minValue: function minValue() {
      if (this.type !== 'number') {
        return null;
      }
      if (this.min || this.min === 0) {
        return this.min;
      }
      return null;
    },
    maxValue: function maxValue() {
      if (this.type !== 'number') {
        return null;
      }
      if (this.max || this.max === 0) {
        return this.max;
      }
      return null;
    },
    stepValue: function stepValue() {
      if (this.type === 'number') {
        return this.step;
      }
      return null;
    }
  },

  events: {
    'ui-input::reset': function uiInputReset(id) {
      var _this = this;

      // Abort if reset event isn't meant for this component
      if (!this.eventTargetsComponent(id)) {
        return;
      }
      // Temporarily disable watcher
      this.ignoreValueChange = true;
      // Blur the input if it's focussed to prevent required errors
      // when it's value is reset
      if (document.activeElement === this.$el.querySelector('input') || document.activeElement === this.$el.querySelector('textarea')) {
        document.activeElement.blur();
      }
      // Reset state
      this.validationError = '';
      this.value = this.initialValue;
      this.valid = true;
      this.dirty = false;
      // Re-enable watcher
      this.$nextTick(function () {
        _this.ignoreValueChange = false;
      });
    }
  },

  methods: {
    focussed: function focussed() {
      this.active = true;
      this.$dispatch('focussed');
    },
    blurred: function blurred() {
      this.active = false;
      if (!this.dirty) {
        this.dirty = true;
      }
      this.$dispatch('blurred');
      this.validate();
    },
    changed: function changed() {
      this.$dispatch('changed');
    },
    keydown: function keydown(e) {
      this.$dispatch('keydown', e);
    },
    keydownEnter: function keydownEnter(e) {
      this.$dispatch('keydown-enter', e);
    }
  },

  filters: {
    trim: {
      // Trim the value when it's written to the model
      write: function write(value) {
        if (this.type !== 'number' && this.trimValue) {
          return value.trim();
        }
        return value;
      }
    }
  },

  components: {
    UiIcon: _UiIcon2.default
  },

  directives: {
    autofocus: _autofocus2.default
  },
  mixins: [_HasTextInput2.default, _ValidatesInput2.default]
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-textbox\" :class=\"{ 'disabled': disabled, 'invalid': !valid, 'dirty': dirty, 'active': active, 'has-label': !hideLabel, 'is-multi-line': multiLine, 'icon-right': iconRight, 'has-counter': maxLength}\">\n  <div class=\"ui-textbox-icon-wrapper\" v-if=\"showIcon\">\n    <ui-icon :icon=\"icon\" class=\"ui-textbox-icon\"></ui-icon>\n  </div>\n\n  <div class=\"ui-textbox-content\">\n    <label class=\"ui-textbox-label\">\n      <div class=\"ui-textbox-label-text\" v-text=\"label\" v-if=\"!hideLabel\"></div>\n      <input class=\"ui-textbox-input\" :type=\"type\" :placeholder=\"placeholder\" :name=\"name\" :id=\"id\" :number=\"type === 'number' ? true : null\" :min=\"minValue\" :max=\"maxValue\" :step=\"stepValue\" :autocomplete=\"autocomplete ? autocomplete : null\" @focus=\"focussed\" @blur=\"blurred\" @change=\"changed\" @keydown=\"keydown\" @keydown.enter=\"keydownEnter\" :debounce=\"debounce\" v-model=\"value | trim\" v-disabled=\"disabled\" v-if=\"!multiLine\" v-autofocus=\"autofocus\">\n      <textarea class=\"ui-textbox-textarea\" :placeholder=\"placeholder\" :name=\"name\" :id=\"id\" :rows=\"rows\" @focus=\"focussed\" @blur=\"blurred\" @change=\"changed\" @keydown=\"keydown\" @keydown.enter=\"keydownEnter\" :debounce=\"debounce\" v-model=\"value | trim\" v-disabled=\"disabled\" v-else=\"\"></textarea>\n    </label>\n    <div class=\"ui-textbox-feedback\" v-if=\"showFeedback || maxLength\">\n      <div class=\"ui-textbox-error-text\" transition=\"ui-textbox-feedback-toggle\" v-text=\"validationError\" v-show=\"!hideValidationErrors &amp;&amp; !valid\"></div>\n      <div class=\"ui-textbox-help-text\" transition=\"ui-textbox-feedback-toggle\" v-text=\"helpText\" v-else=\"\"></div>\n      <div class=\"ui-textbox-counter\" v-text=\"value.length + '/' + maxLength\" v-if=\"maxLength\"></div>\n    </div>\n  </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-1e06b70e", module.exports)
  } else {
    hotAPI.update("_v-1e06b70e", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}