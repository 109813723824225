'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'ui-progress-linear',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'indeterminate'
    },
    color: {
      type: String,
      default: 'primary',
      coerce: function coerce(color) {
        return 'color-' + color;
      }
    },
    value: {
      type: Number,
      coerce: Number,
      default: 0
    }
  },

  computed: {
    progress: function progress() {
      if (this.value < 0) {
        return 0;
      }
      if (this.value > 100) {
        return 100;
      }
      return this.value;
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-progress-linear\" :class=\"[color]\" v-show=\"show\" transition=\"ui-progress-linear-toggle\">\n  <div v-if=\"type === 'indeterminate'\" class=\"ui-progress-linear-indeterminate\" role=\"progressbar\" :aria-valuemin=\"0\" :aria-valuemax=\"100\"></div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-10105887", module.exports)
  } else {
    hotAPI.update("_v-10105887", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}