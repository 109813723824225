import UiButton from '../material/UiButton.vue'
import UiTextbox from '../material/UiTextbox.vue'
import UiProgressLinear from '../material/UiProgressLinear.vue'
export default {
  route: {
    activate () {
      this.titleShow = true
    },
    canDeactivate (transition) {
      this.titleShow = false
      transition.next()
    }
  },
  components: {
    UiButton,
    UiTextbox,
    UiProgressLinear
  },
  data () {
    return {
      base_url: 'http://192.168.1.115:8000/',
      titleShow: false,
      role: window.localStorage.getItem('role'),
      local: window.localStorage.getItem('local'),
      uid: window.localStorage.getItem('uid')
    }
  },
  methods: {
    isDataNull (_val) {
      return (_val === undefined || _val == null || _val.length <= 0) ? 1 : 0
    },
    isObject (obj) {
      return obj !== null && typeof obj === 'object'
    },
    backToUrl (_name, _params = '') {
      if (_params.length > 0) {
        this.$router.go({
          name: _name,
          params: {
            id: _params
          }
        })
      } else {
        this.$router.go({
          name: _name
        })
      }
    }
  }
}
