'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _UiMenuOption = require('./UiMenuOption.vue');

var _UiMenuOption2 = _interopRequireDefault(_UiMenuOption);

var _ShowsDropdown = require('./mixins/ShowsDropdown');

var _ShowsDropdown2 = _interopRequireDefault(_ShowsDropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-menu',

  props: {
    options: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    showIcons: {
      type: Boolean,
      default: false
    },
    showSecondaryText: {
      type: Boolean,
      default: false
    },
    hideRippleInk: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    partial: {
      type: String,
      default: 'ui-menu-default'
    }
  },
  events: {
    'dropdown-opened': function dropdownOpened() {
      if (this.containFocus) {
        document.addEventListener('focus', this.restrictFocus, true);
      }
      this.$dispatch('opened');
      return true;
    },

    'dropdown-closed': function dropdownClosed() {
      if (this.containFocus) {
        document.removeEventListener('focus', this.restrictFocus, true);
      }
      this.$dispatch('closed');
      return true;
    }
  },

  methods: {
    optionSelect: function optionSelect(option) {
      if (!(option.disabled || option.type === 'divider')) {
        this.$dispatch('option-selected', option);
        if (this.closeOnSelect) {
          this.closeDropdown();
        }
      }
    },
    restrictFocus: function restrictFocus(e) {
      if (!this.$els.dropdown.contains(e.target)) {
        e.stopPropagation();
        this.$els.dropdown.querySelector('.ui-menu-option').focus();
      }
    },
    redirectFocus: function redirectFocus(e) {
      e.stopPropagation();
      this.$els.dropdown.querySelector('.ui-menu-option').focus();
    }
  },
  components: {
    UiMenuOption: _UiMenuOption2.default
  },
  mixins: [_ShowsDropdown2.default]
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<ul class=\"ui-menu\" role=\"menu\" tabindex=\"-1\" @keydown.esc=\"closeDropdown\" v-el:dropdown=\"\" :class=\"{ 'has-icons': showIcons, 'has-secondary-text': showSecondaryText }\">\n  <ui-menu-option :type=\"option.type\" :icon=\"option.icon\" :text=\"option.text\" :disabled=\"option.disabled\" :secondary-text=\"option.secondaryText\" :option=\"option\" :show-icon=\"showIcons\" :show-secondary-text=\"showSecondaryText\" :hide-ripple-ink=\"hideRippleInk\" :partial=\"option.partial || partial\" @keydown.enter.prevent=\"optionSelect(option)\" @click=\"optionSelect(option)\" v-for=\"option in options\"></ui-menu-option>\n  <div class=\"ui-menu-focus-redirector\" @focus=\"redirectFocus\" tabindex=\"0\"></div>\n</ul>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-48c9c018", module.exports)
  } else {
    hotAPI.update("_v-48c9c018", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}