'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _disabled = require('./directives/disabled');

var _disabled2 = _interopRequireDefault(_disabled);

var _UiRadio = require('./UiRadio.vue');

var _UiRadio2 = _interopRequireDefault(_UiRadio);

var _ReceivesTargetedEvent = require('./mixins/ReceivesTargetedEvent');

var _ReceivesTargetedEvent2 = _interopRequireDefault(_ReceivesTargetedEvent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-radio-group',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: '',
      twoWay: true
    },
    options: {
      type: Array,
      required: true
    },
    label: String,
    hideLabel: {
      type: Boolean,
      default: false
    },
    helpText: String,
    vertical: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      active: false,
      initialValue: ''
    };
  },
  created: function created() {
    // Cache initial value for later reset
    this.initialValue = this.value;
  },

  computed: {
    showFeedback: function showFeedback() {
      return Boolean(this.helpText);
    }
  },
  events: {
    'ui-input::reset': function uiInputReset(id) {
      // Abort if reset event isn't meant for this component
      if (!this.eventTargetsComponent(id)) {
        return;
      }
      this.value = this.initialValue;
    }
  },
  methods: {
    focus: function focus() {
      this.active = true;
    },
    blur: function blur() {
      this.active = false;
    }
  },
  components: {
    UiRadio: _UiRadio2.default
  },
  directives: {
    disabled: _disabled2.default
  },
  mixins: [_ReceivesTargetedEvent2.default]
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"ui-radio-group\" :id=\"id\" :class=\"{ 'disabled': disabled, 'active': active, 'vertical': vertical }\">\n    <div class=\"ui-radio-group-label\" v-text=\"label\" v-if=\"!hideLabel\"></div>\n\n    <div class=\"ui-radio-group-options-wrapper\">\n        <ui-radio class=\"ui-radio-group-radio\" v-for=\"option in options\" :model.sync=\"value\" :name=\"name\" :label=\"option.text || option\" :value=\"option.value || option\" :disabled=\"disabled || option.disabled\" @focussed=\"focus\" @blurred=\"blur\"></ui-radio>\n    </div>\n\n    <div class=\"ui-radio-group-feedback\" v-if=\"showFeedback\" transition=\"ui-radio-group-feedback-toggle\">\n        <div class=\"ui-radio-group-help-text\" v-text=\"helpText\"></div>\n    </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-b61f14ce", module.exports)
  } else {
    hotAPI.update("_v-b61f14ce", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}