;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div id=\"app\">\n  <router-view></router-view>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-75b3b626", module.exports)
  } else {
    hotAPI.update("_v-75b3b626", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}