'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _globals = require('../mixins/globals');

var _globals2 = _interopRequireDefault(_globals);

var _UiSelect = require('../material/UiSelect.vue');

var _UiSelect2 = _interopRequireDefault(_UiSelect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'CrearLocal',
  mixins: [_globals2.default],
  components: {
    UiSelect: _UiSelect2.default
  },
  data: function data() {
    return {
      restaurants: [],
      local: {
        name: '',
        address: '',
        tempRestaurant: null
      },
      disabledCancel: false,
      loadingCreate: false,
      disabledCreate: false,
      flagName: false,
      flagAddress: false,
      flagRestaurant: false,
      flagAdmin: false
    };
  },

  watch: {
    'local.name': function localName(val, oldval) {
      if (val.length > 2) {
        this.flagName = true;
        if (this.flagAddress && this.flagRestaurant) {
          this.disabledCreate = true;
        } else {
          this.disabledCreate = false;
        }
      } else {
        this.flagName = false;
        this.disabledCreate = false;
      }
    },
    'local.address': function localAddress(val, oldval) {
      if (val.length > 2) {
        this.flagAddress = true;
        if (this.flagName && this.flagRestaurant) {
          this.disabledCreate = true;
        } else {
          this.disabledCreate = false;
        }
      } else {
        this.flagAddress = false;
        this.disabledCreate = false;
      }
    },
    'local.tempRestaurant': function localTempRestaurant(val, oldval) {
      if (val !== null && typeof val !== 'undefined') {
        this.flagRestaurant = true;
        if (this.flagName && this.flagAddress) {
          this.disabledCreate = true;
        } else {
          this.disabledCreate = false;
        }
      } else {
        this.flagAddress = false;
        this.disabledCreate = false;
      }
    }
  },
  computed: {
    setRestaurant: function setRestaurant() {
      for (var i in this.restaurants) {
        if (this.restaurants[i].value == window.localStorage.getItem('restaurante')) {
          this.local.tempRestaurant = this.restaurants[i];
          this.flagRestaurant = true;
          return this.local.tempRestaurant.text;
        }
      }
    }
  },
  created: function created() {
    this.fetchRestaurant();
    this.flagAdmin = this.isCurrentUserAdmin();
  },

  methods: {
    isCurrentUserAdmin: function isCurrentUserAdmin() {
      if (window.localStorage.getItem('role') === 'admin') {
        return true;
      } else {
        return false;
      }
    },
    onSubmitCreate: function onSubmitCreate() {
      var _this = this;

      var _url = '/admin/local/create';
      this.disabledCancel = true;
      this.loadingCreate = true;
      var _temp = {
        name: this.local.name,
        address: this.local.address,
        restaurante_id: this.local.tempRestaurant.value
      };
      this.$http.post(_url, _temp).then(function (response) {
        _this.disabledCancel = false;
        _this.loadingCreate = false;
        _this.$router.go({
          name: 'locales-lista'
        });
      }, function (response) {
        _this.disabledCancel = false;
        _this.loadingCreate = false;
      });
    },
    fetchRestaurant: function fetchRestaurant() {
      var _this2 = this;

      var _url = '/admin/restaurant';

      this.$http({
        url: _url,
        method: 'GET'
      }).then(function (response) {
        var _data = response.data;
        _data.forEach(function (val) {
          _this2.restaurants.push({
            text: val.name,
            value: val.id + ''
          });
        });
        // let _data = response.data
        _this2.$nextTick(function () {
          _this2.responseUsers = true;
        });
      }, function (response) {
        if (response.status === 401 || response.status === 403) {
          window.localStorage.removeItem('restaurante');
          window.localStorage.removeItem('local');
          window.localStorage.removeItem('role');
          window.localStorage.removeItem('uid');
          _this2.person = response.data;
          _this2.$router.go({
            name: 'sign'
          });
        }
      });
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"bgTransition\">\n  <div class=\"header-section h_person\">\n    <h2 v-if=\"titleShow\" transition=\"slideLeft\">Crear Local</h2>\n  </div>\n  <section class=\"section-person\">\n    <div class=\"width-person bg-white\" style=\"padding: 15px;\">\n      <ui-textbox type=\"text\" name=\"name\" label=\"Nombre (*)\" :value.sync=\"local.name\" autocomplete=\"off\"></ui-textbox>\n\n      <ui-textbox type=\"text\" name=\"address\" label=\"Dirección (*)\" :value.sync=\"local.address\" autocomplete=\"off\"></ui-textbox>\n\n      <ui-select v-if=\"flagAdmin\" name=\"level\" label=\"Restaurante(*)\" :options=\"restaurants\" placeholder=\"Seleccione una opción\" :value.sync=\"local.tempRestaurant\"></ui-select>\n\n      <ui-textbox v-if=\"!flagAdmin\" type=\"text\" name=\"restaurant\" label=\"Restaurante(*)\" :value.sync=\"setRestaurant\" disabled=\"\"></ui-textbox>\n\n      <p style=\"font-size:12px; color: rgba(0, 0, 0, 0.54);\">(*) Campos requeridos</p>\n\n      <div class=\"text-center\" style=\"color: #fff;\">\n        <ui-button @click=\"backToUrl('locales-lista')\" text=\"Cancelar\" color=\"lanacional-primario\" :disabled=\"disabledCancel\" raised=\"\"></ui-button>\n\n        <ui-button @click=\"onSubmitCreate()\" text=\"Guardar\" color=\"lanacional-cuarto\" :loading=\"loadingCreate\" :disabled=\"!disabledCreate\"></ui-button>\n      </div>\n    </div>\n  </section>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-f9fe4e0a", module.exports)
  } else {
    hotAPI.update("_v-f9fe4e0a", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}