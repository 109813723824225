'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _disabled = require('./directives/disabled');

var _disabled2 = _interopRequireDefault(_disabled);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-radio',
  props: {
    id: String,
    name: String,
    model: {
      type: String,
      default: '',
      twoWay: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    value: String,
    label: String,
    hideLabel: {
      type: Boolean,
      default: false
    },
    labelLeft: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      active: false
    };
  },

  methods: {
    focus: function focus() {
      this.active = true;
      this.$dispatch('focussed');
    },
    blur: function blur() {
      this.active = false;
      this.$dispatch('blurred');
    }
  },
  directives: {
    disabled: _disabled2.default
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<label class=\"ui-radio\" :class=\"{ 'disabled': disabled, 'checked': active, 'label-left': labelLeft }\">\n  <div class=\"ui-radio-input-wrapper\">\n    <input class=\"ui-radio-input\" type=\"radio\" :id=\"id\" :name=\"name\" :value=\"value\" :checked=\"checked\" @focus=\"focus\" @blur=\"blur\" v-model=\"model\" v-disabled=\"disabled\">\n    <span class=\"ui-radio-border\"></span>\n    <span class=\"ui-radio-inner-dot\"></span>\n  </div>\n  <div class=\"ui-radio-label-text\" v-if=\"!hideLabel\">\n    <slot>\n      <span v-text=\"label\"></span>\n    </slot>\n  </div>\n</label>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-88d5bd94", module.exports)
  } else {
    hotAPI.update("_v-88d5bd94", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}