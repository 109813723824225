'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Navbar = require('./Navbar.vue');

var _Navbar2 = _interopRequireDefault(_Navbar);

var _globals = require('../mixins/globals');

var _globals2 = _interopRequireDefault(_globals);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'Base',
  mixins: [_globals2.default],
  components: {
    Navbar: _Navbar2.default
  },
  data: function data() {
    return {
      person: {},
      viewOption: false,
      activeModal: false,
      modalOnOff: false,
      load_person: false,
      activeAccount: false
    };
  },

  events: {
    closeOption: function closeOption() {
      this.hideOption();
    }
  },
  ready: function ready() {
    this.fetchUser();
  },

  methods: {
    fetchUser: function fetchUser() {
      var _this = this;

      var _url = '/admin/users/' + this.uid;

      this.$http({
        url: _url,
        method: 'GET'
      }).then(function (response) {
        _this.person = response.data;
        _this.load_person = true;
      }, function (response) {
        if (response.status === 401 || response.status === 403) {
          window.localStorage.removeItem('restaurante');
          window.localStorage.removeItem('local');
          window.localStorage.removeItem('role');
          window.localStorage.removeItem('uid');
          // this.person = response.data
          _this.$router.go({
            name: 'sign'
          });
        }
      });
    },
    showOption: function showOption() {
      if (!this.viewOption) {
        this.viewOption = true;
      } else {
        this.viewOption = false;
      }
    },
    hideOption: function hideOption() {
      if (this.viewOption) {
        this.viewOption = false;
      } else {
        this.viewOption = true;
      }
    },
    signOut: function signOut() {
      var _this2 = this;

      var _url = '/logout';

      this.$http({
        url: _url,
        method: 'POST'
      }).then(function (response) {
        window.localStorage.removeItem('restaurante');
        window.localStorage.removeItem('local');
        window.localStorage.removeItem('role');
        window.localStorage.removeItem('uid');
        _this2.person = response.data;
        _this2.$router.go({
          name: 'sign'
        });
      }, function (response) {});
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"container-layout\">\n  <section class=\"content-layout\">\n    <navbar></navbar>\n    <section class=\"content-main\">\n      <header class=\"header-user\">\n        <div class=\"clearfix\">\n          <div class=\"b-right\" v-if=\"load_person\" transition=\"slideRight\">\n            <div class=\"sign\">\n              <ul class=\"nav nav_opt\"> \n                <li>\n                  <div @click.stop=\"showOption\" class=\"picture-user\">\n                    <span class=\"name-user\">{{person.name | capitalize}}<i class=\"material-icons\">keyboard_arrow_down</i></span>\n                  </div>\n                  <ul v-if=\"viewOption\" v-click-outside=\"closeOption\" class=\"nav nav_menu\">\n                    <!-- li>\n                      <a @click=\"viewOption=false\" v-link=\"{name: 'base'}\">Perfil</a>\n                    </li -->\n                    <li>\n                      <button type=\"button\" class=\"btn-logout\" role=\"button\" @click=\"signOut()\">Salir</button>\n                    </li>\n                  </ul>\n                </li>\n              </ul> \n            </div>\n          </div>\n        </div>\n      </header>\n      <router-view transition=\"fade\"></router-view>\n    </section>\n  </section>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-cccce266", module.exports)
  } else {
    hotAPI.update("_v-cccce266", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}