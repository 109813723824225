'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _UiIcon = require('./UiIcon.vue');

var _UiIcon2 = _interopRequireDefault(_UiIcon);

var _UiMenu = require('./UiMenu.vue');

var _UiMenu2 = _interopRequireDefault(_UiMenu);

var _UiProgressCircular = require('./UiProgressCircular.vue');

var _UiProgressCircular2 = _interopRequireDefault(_UiProgressCircular);

var _disabled = require('./directives/disabled');

var _disabled2 = _interopRequireDefault(_disabled);

var _HasDropdown = require('./mixins/HasDropdown');

var _HasDropdown2 = _interopRequireDefault(_HasDropdown);

var _ShowsRippleInk = require('./mixins/ShowsRippleInk');

var _ShowsRippleInk2 = _interopRequireDefault(_ShowsRippleInk);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-button',

  props: {
    type: {
      type: String,
      default: 'normal',
      coerce: function coerce(type) {
        return 'ui-button-' + type;
      }
    },
    buttonType: {
      type: String,
      default: 'submit'
    },
    color: {
      type: String,
      default: 'default',
      coerce: function coerce(color) {
        return 'color-' + color;
      }
    },
    raised: {
      type: Boolean,
      default: false
    },
    text: String,
    icon: String,
    iconRight: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showDropdownIcon: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styleClasses: function styleClasses() {
      var classes = [this.type, this.color];
      if (this.raised) {
        classes.push('ui-button-raised');
      }
      if (this.hasDropdownMenu || this.hasPopover) {
        classes.push('has-dropdown');
      }
      return classes;
    },
    spinnerColor: function spinnerColor() {
      if (this.color === 'color-default' || this.type === 'ui-button-flat') {
        return 'black';
      }
      return 'white';
    },
    showIcon: function showIcon() {
      return Boolean(this.icon);
    }
  },
  components: {
    UiIcon: _UiIcon2.default,
    UiMenu: _UiMenu2.default,
    UiProgressCircular: _UiProgressCircular2.default
  },
  mixins: [_HasDropdown2.default, _ShowsRippleInk2.default],
  directives: {
    disabled: _disabled2.default
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<button class=\"ui-button\" :class=\"styleClasses\" :type=\"buttonType\" v-disabled=\"disabled || loading\" v-el:button=\"\">\n  <div class=\"ui-button-content\" :class=\"{ 'invisible': loading }\">\n    <ui-icon class=\"ui-button-icon\" :class=\"{ 'position-right': iconRight }\" :icon=\"icon\" v-if=\"showIcon\"></ui-icon>\n    <div class=\"ui-button-text\">\n      <slot>\n        <span v-text=\"text\"></span>\n      </slot>\n    </div>\n    <ui-icon class=\"ui-button-dropdown-icon\" icon=\"\" v-if=\"!iconRight &amp;&amp; showDropdownIcon &amp;&amp; (hasDropdownMenu || hasPopover)\"></ui-icon>\n  </div>\n\n  <ui-progress-circular class=\"ui-button-spinner\" :color=\"spinnerColor\" :size=\"18\" :stroke=\"4.5\" disable-transition=\"\" v-show=\"loading\"></ui-progress-circular>\n\n  <ui-ripple-ink v-if=\"!hideRippleInk &amp;&amp; !disabled\" :trigger=\"$els.button\"></ui-ripple-ink>\n\n  <ui-menu class=\"ui-button-dropdown-menu\" :trigger=\"$els.button\" :options=\"menuOptions\" :show-icons=\"showMenuIcons\" :show-secondary-text=\"showMenuSecondaryText\" :open-on=\"openDropdownOn\" @option-selected=\"menuOptionSelect\" :dropdown-position=\"dropdownPosition\" v-if=\"hasDropdownMenu\"></ui-menu>\n</button>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-61a5b5f2", module.exports)
  } else {
    hotAPI.update("_v-61a5b5f2", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}