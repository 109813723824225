import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'

import VueHighcharts from 'vue-highcharts'
import Highcharts from 'highcharts/highcharts'


Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(VueHighcharts, { Highcharts } )

Vue.directive('click-outside', {
  priority: 700,
  bind () {
    let self  = this
    this.event = (event) => {
      self.vm.$emit(self.expression, event)
    }
    this.el.addEventListener('click', this.stopProp)
    document.body.addEventListener('click', this.event)
  },
  unbind () {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },
  stopProp (event) {
    event.stopPropagation()
  }
})

const router = new VueRouter({
  hashbang: true,
  history: false,
  root: '',
  mode: 'html5',
  transitionOnLoad: true,
  saveScrollPosition: false
})

router.map({
  '/': {
    name: 'sign',
    component: require('./pages/Sign.vue'),
    guest: true
  },
  '/admin': {
    name: 'base',
    component: require('./pages/Base.vue'),
    subRoutes: {
      '/usuarios': {
        name: 'usuarios-lista',
        component: require('./pages/UserList.vue')
      },
      '/usuarios/crear': {
        name: 'usuario-crear',
        component: require('./pages/UserCreate.vue')
      },
      '/usuarios/:id': {
        name: 'usuario-editar',
        component: require('./pages/UserEdit.vue')
      },

      '/comentarios': {
        name: 'comentario-lista',
        component: require('./pages/CommentList.vue')
      },

      '/locales': {
        name: 'locales-lista',
        component: require('./pages/LocalList.vue')
      },
      '/locales/crear': {
        name: 'local-crear',
        component: require('./pages/LocalCreate.vue')
      },
      '/locales/:id': {
        name: 'local-editar',
        component: require('./pages/LocalEdit.vue')
      },
      '/reporte-local': {
        name: 'reporte-local',
        component: require('./pages/ChartLocal.vue')
      },
      '/reporte-restaurante': {
        name: 'reporte-restaurante',
        component: require('./pages/ChartRestaurante.vue')
      },
      '/otras-caracteristicas': {
        name: 'otras-caracteristicas',
        component: require('./pages/ChartOtrasCaracteristicas.vue')
      },
      '/reporte-caracteristica': {
        name: 'reporte-feature',
        component: require('./pages/ChartFeature.vue')
      },
      '/reporte-nps': {
        name: 'reporte-nps',
        component: require('./pages/ChartNPS.vue')
      }
    }
  },
  '/app': {
    name: 'client',
    component: require('./pages/Client.vue'),
    guest: true
  }
})

router.beforeEach(transition => {
  let role = window.localStorage.getItem('role')
  let _path = transition.to.path
  if (role === null || role === undefined) {
    if (transition.to.name === 'sign' || transition.to.name === 'client') {
      transition.next()
    } else {
      transition.redirect('/')
    }
  } else {
    if(role === 'admin' || role === 'gerente' || role === 'subgerente'){
      if (transition.to.name === 'sign') {
        transition.redirect('/admin/usuarios')
      } else {
        let _f = _path.includes('app')
        if (_f) {
          transition.redirect('/admin/usuarios')
        } else {
          transition.next()
        }
      }
    }
  }
})

/*var App = Vue.extend({
  data() {
    return {
    }
  },
  methods: {

  }
});*/

router.start(App, 'App')
