'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _UiIcon = require('./UiIcon.vue');

var _UiIcon2 = _interopRequireDefault(_UiIcon);

var _ShowsRippleInk = require('./mixins/ShowsRippleInk');

var _ShowsRippleInk2 = _interopRequireDefault(_ShowsRippleInk);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-menu-option',

  props: {
    type: String,
    text: String,
    icon: String,
    showIcon: {
      type: Boolean,
      default: false
    },
    secondaryText: String,
    showSecondaryText: {
      type: Boolean,
      default: false
    },
    partial: {
      type: String,
      default: 'ui-menu-default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    option: Object
  },

  computed: {
    isDivider: function isDivider() {
      return this.type === 'divider';
    }
  },
  components: {
    UiIcon: _UiIcon2.default
  },
  partials: {
    'ui-menu-default': '\n        <ui-icon\n            class="ui-menu-option-icon" :icon="icon" v-if="showIcon && !isDivider && icon"\n        ></ui-icon>\n\n        <div class="ui-menu-option-text" v-text="text" v-if="!isDivider"></div>\n\n        <div\n            class="ui-menu-option-secondary-text" v-text="secondaryText"\n            v-if="showSecondaryText && !isDivider && secondaryText"\n        ></div>\n      '
  },
  mixins: [_ShowsRippleInk2.default]
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<a class=\"ui-menu-option\" role=\"menu-item\" :tabindex=\"(isDivider || disabled) ? null : '0'\" :class=\"{ 'divider': isDivider, 'disabled' : disabled }\">\n  <div class=\"ui-menu-option-content\" :class=\"[partial]\">\n    <partial :name=\"partial\"></partial>\n  </div>\n  <ui-ripple-ink :trigger=\"$el\" v-if=\"!hideRippleInk &amp;&amp; !disabled &amp;&amp; !isDivider\"></ui-ripple-ink>\n</a>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-09c865ee", module.exports)
  } else {
    hotAPI.update("_v-09c865ee", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}